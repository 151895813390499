



























import { defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
  components: { ODegreeList: () => import('@/components/organisms/globalTools/degree/o-degree-list.vue')},

  setup(){
    const state = reactive({
      search: ''
    })

    return { state }
  }
})
